import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mapKeys, camelCase } from 'lodash'

function isBadRequest({ status }) {
    console.log(status)
    return status === 400;
}

function isUnprocessableEntity({ status }) {
    console.log(status)
    return status === 422;
}

function transformData(values) {
    return mapKeys(values, (value, key) => camelCase(key));
}

export default function useForm({ initialValues, onSubmit, onSuccess, onFail, validationMessages = [] }) {
    const { t } = useTranslation();
    const [values, setValues] = useState(initialValues || {});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    function setValue(name, value) {
        setValues({
            ...values,
            [name]: value,
        });
    }

    function clearValues(...names) {
        const clearedValues = names.reduce((clearedValues, name) => {
            clearedValues[name] = undefined;

            return clearedValues;
        }, {});

        setValues({ ...values, ...clearedValues });
    }

    function onChangeValue({ target }) {
        const { name, value } = target;

        setValues({
            ...values,
            [name]: value,
        });
    }

    function onChangeValueByName(name) {
        return value => {
            setValue(name, value);
        };
    }

    function setError(name, error) {
        setErrors({
            ...errors,
            [name]: error,
        });
    }

    async function submit(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            const { data } = await onSubmit(values, e);
            setErrors({});
            onSuccess && onSuccess(transformData(data));
            setIsLoading(false);
        } catch (error) {
            handleErrors(error);
            setIsLoading(false);
        }
    }

    function handleErrors(error) {
        if(error) {
            const { response } = error;
            const handledErrors = onFail && onFail(error);

            if (handledErrors) {
                setErrors(handledErrors);
                return;
            }

            if (response) {
                if (isUnprocessableEntity(response)) {
                    setErrorsFromValidationMessages(response.data);
                    return;
                }
            }
            setError('general', t('general.errors.somethingWentWrong'));
        } else {
            setError('general', t('general.errors.somethingWentWrong'));
        }
    }

    function setErrorsFromValidationMessages({ errors: originalErrors }) {
        const errors = transformData(originalErrors);

       for (const name in errors) {
           errors[name] = validationMessages[name] || errors[name];
       }

       setErrors(errors);
    }

    return {
        values,
        initialValues,
        setValues,
        setValue,
        clearValues,
        onChangeValue,
        onChangeValueByName,
        errors,
        setErrors,
        setError,
        isLoading,
        submit,
        isBadRequest,
        isUnprocessableEntity,
    };
}
