import React from 'react'
//import { useTranslation } from 'react-i18next'
import { Router } from "@reach/router"
import TeachersOverview from "src/components/teachers/Overview";
import Detail from "src/components/teachers/Detail";

export default function Teachers() {
    //const { t } = useTranslation();
    return (
        <Router>
            <TeachersOverview path="teachers" />
            <Detail path="teachers/:id" />
        </Router>
    )
}
