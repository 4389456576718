import React, { useEffect } from 'react'
import { useOAuthTokens } from 'src/hooks/useOAuthTokens'
import { AuthenticatedApiProvider } from 'src/context/AuthenticatedApiContext'

export default function LogoutPage() {
    const { accessToken, forgetOAuthTokens } = useOAuthTokens();

    useEffect(forgetOAuthTokens, [accessToken]);

    return <AuthenticatedApiProvider />;
}
