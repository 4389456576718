import React from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next'
import i18n from 'src/i18n/i18next'
import { GuestApiProvider } from 'src/context/GuestApiContext'

import Main from 'src/components/sections/Main';
import Footer from 'src/components/sections/Footer';

export default function GuestLayout({ page, metaTitle, children }) {
  return (
    <I18nextProvider i18n={i18n}>
      <GuestApiProvider>
        <Helmet bodyAttributes={{class: 'page-' + page}}>
          <meta charSet="utf-8" />
          <title>{metaTitle && metaTitle.length ? metaTitle + ' | ' : '' }Whambam</title>
        </Helmet>
        <div className={classNames('wrapper min-h-screen flex flex-col')}>
          <Main page={page} className={classNames('relative flex-1')}>
            {children}
          </Main>
          <Footer className={classNames('flex-shrink')} />
        </div>
      </GuestApiProvider>
    </I18nextProvider>
  );
}

GuestLayout.defaultProps = {
  page: '',
  metaTitle: '',
};
