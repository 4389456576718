import { navigate } from 'gatsby'

export const dashboardRoute = '/dashboard';
export const logoutRoute = '/logout';
export const loginRoute = '/';
export const teachersRoute = '/teachers';
export const studentsRoute = '/students';

export function goToLogin() {
    navigate(loginRoute);
}
