import React, { useMemo, useEffect, useState } from 'react'
import { Card, CardHeader, CardContent } from 'src/components/layout/card/Card'
import DataTable from 'react-data-table-component';
import { navigate } from 'gatsby'
import { useAuthenticatedApi } from "src/hooks/useApi";
import PrimaryButton from '../../components/interactivity/Button';
import SearchForm from '../interactivity/SearchForm'
import Puffer from '../graphics/loaders/Puffer'

export default function StudentsList({ className }) {
    const scrollHeight = false; //window.innerHeight / 2;
    const { getStudents } = useAuthenticatedApi();
    const [students, setStudents] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchStudents(page)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchStudents(page) {
        getStudents({page: page, searchQuery: searchQuery}).then((response) => {
            setStudents(response.data)
            setPagination(response.meta)
            setPage(page)
            setLoading(false);
        })
    }

    useEffect(() => {
        if(searchQuery !== null) {
            if(searchQuery.length >= 3) {
                fetchStudents(1)
            } else if(searchQuery.length === 0) {
                fetchStudents(1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    const columns = useMemo(() => [
        {
            name: <h4 className="uppercase font-headers font-bold">ID</h4>,
            selector: 'id',
            sortable: false,
            /*style: {
                flex: '0 0 60px',
                minWidth: 'unset',
                borderRight: '1px solid #efefef',
            },*/
        },
        {
            name: <h4 className="uppercase font-headers font-bold">First name</h4>,
            selector: 'first_name',
            sortable: false,
        },
        {
            name: <h4 className="uppercase font-headers font-bold">Last name</h4>,
            selector: 'last_name',
            sortable: false,
        },
        {
            name: <h4 className="uppercase font-headers font-bold">Email</h4>,
            selector: 'email',
            sortable: false,
        },
        {
            name: '',
            cell: row => <PrimaryButton onClick={() => navigateToDetail(row)}>View</PrimaryButton>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ], []);

    function navigateToDetail (value) {
        navigate(`/students/${value.id}`, {state: {student: value}})
    }

    function changePage(value) {
        fetchStudents(value)
    }

    return (
        <Card className={className}>
            <CardHeader title="Overview">
                <SearchForm onQueryChange={(text) => setSearchQuery(text.target.value)} />
            </CardHeader>
            <CardContent className="py-0">
                {loading ? <Puffer type="dark" className="py-32 flex justify-center items-center" /> : (pagination &&
                    <DataTable
                        columns={columns}
                        data={students}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={scrollHeight + 'px'}
                        pagination={true}
                        paginationServer={true}
                        paginationDefaultPage={1}
                        paginationTotalRows={pagination.total}
                        paginationPerPage={15}
                        paginationRowsPerPageOptions={[15]}
                        onChangePage={changePage}
                        progressPending={loading}
                        progressComponent={<Puffer type="dark" />}
                    />)
                }
            </CardContent>
        </Card>
    );
}
