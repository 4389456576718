import React from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'src/components/typography/PageTitle'
import AuthenticatedLayout from "src/components/layout/wrappers/AuthenticatedLayout";
import TeacherCount from "src/components/teachers/TeacherCount";
import StudentCount from "src/components/students/StudentCount";

export default function IndexPage({location}) {
  const { t } = useTranslation();

  return (
    <AuthenticatedLayout metaTitle={t('dashboard.pageTitle')} page="dashboard">
      <PageTitle className="mb-12">{t('dashboard.welcomeTitle')}</PageTitle>
      <div className="flex flex-wrap">

        <TeacherCount/>
        <StudentCount/>

      </div>

    </AuthenticatedLayout>
  );
}
