import React from 'react'

export default function Spinner({ size, className }) {
    return (
        <svg
            width={size}
            height={size}
            className={`spinner-circle ${className}`}
            viewBox="0 0 50 50"
        >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
        </svg>
    );
}

Spinner.defaultProps = {
    size: 20,
};
