import React from 'react'
//import { useTranslation } from 'react-i18next'
import { Router } from "@reach/router"
import StudentsOverview from "src/components/students/Overview";
import Detail from "src/components/students/Detail";

export default function Students() {
    //const { t } = useTranslation();
    return (
        <Router>
            <StudentsOverview path="students" />
            <Detail path="students/:id" />
        </Router>
    )
}
