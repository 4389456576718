import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Error } from 'src/components/input'

const DEFAULT = classNames(
    'border border-solid border-gray-300 rounded mb-3',
    'bg-white overflow-hidden flex items-stretch',
    'text-base font-normal',
);

const INPUT = classNames(
    'appearance-none w-full px-4 py-3 flex-1',
    'text-base font-normal',
);

//const DEFAULT = 'appearance-none w-full px-4 py-3 flex-1 outline-none text-base font-normal bg-white text-dark placeholder-grey-800';
//const INPUT = 'appearance-none w-full px-4 py-3 flex-1 outline-none text-base font-normal bg-white text-dark placeholder-grey-800';

const ENABLED = 'bg-white text-gray-800 placeholder-gray-800';
const DISABLED = 'cursor-default bg-gray-100 text-gray-400 placeholder-gray-400';
const BOLD = 'font-medium text-lg leading-none text-center';

export function Affix({ children }) {
    return (
        <div className="flex">
            {children}
        </div>
    );
}

export function InnerInput({
    type,
    name,
    id,
    value,
    isRequired,
    isDisabled,
    isFocused,
    placeholder,
    conditionalClasses,
    onChange,
    onFocus,
    onPaste,
}) {
    const ref = useRef(null);

    useEffect(function focusInput() {
        if (isFocused) {
            ref.current.focus();
        }
    }, [ref, isFocused]);

    return (
        <input
            ref={ref}
            className={classNames(INPUT, conditionalClasses)}
            type={type}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
            onFocus={onFocus}
            onPaste={onPaste}
        />
    );
}

export function InputContainer(props) {
    const {
        //name,
        isDisabled,
        isBold,
        error,
        renderPrefix,
        renderSuffix,
        className,
        renderInnerInput,
    } = props;
    const conditionalClasses = {
        [ENABLED]: !isDisabled,
        [DISABLED]: isDisabled,
        [BOLD]: isBold,
    };

    return (
        <>
            <div className={classNames(DEFAULT, className, conditionalClasses)}>
                {renderPrefix && <Affix>{renderPrefix()}</Affix>}
                {renderInnerInput({ ...props, conditionalClasses })}
                {renderSuffix && <Affix>{renderSuffix()}</Affix>}
            </div>
            <Error>{error}</Error>
        </>
    );
}

export default function Input(props) {
    return (
        <InputContainer
            {...props}
            renderInnerInput={(props) => (
                <InnerInput {...props} />
            )}
        />
    );
}

Input.defaultProps = InputContainer.defaultProps = {
    type: 'text',
    isRequired: false,
    isDisabled: false,
    isFocused: false,
    isBold: false,
    placeholder: undefined,
    error: undefined,
    renderPrefix: undefined,
    renderSuffix: undefined,
    className: undefined,
    onFocus: undefined,
    onPaste: undefined,
};
