import React from 'react'
import AuthenticatedLayout from "src/components/layout/wrappers/AuthenticatedLayout";
import { useTranslation } from 'react-i18next'
import StudentsList from './StudentsList'
import PageTitle from "src/components/typography/PageTitle";

function StudentsOverview({location}) {
    const { t } = useTranslation();
    return (
        <AuthenticatedLayout metaTitle={t('students.pageTitle')} page="students">
            <PageTitle>{t('students.pageTitle')}</PageTitle>
            <StudentsList className="mt-12"/>
        </AuthenticatedLayout>
    );
}
export default StudentsOverview
