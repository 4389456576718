import React from 'react'
import classNames from 'classnames'
import Spinner from 'src/components/graphics/loaders/Spinner'

// text-base

const BASICS = classNames(
    'inline-block appearance-none focus:outline-none',
    'font-bold font-general whitespace-no-wrap',
    'py-2 px-8',
    'transition-all duration-200',
);

const PRIMARY = classNames(
    BASICS,
    'bg-white text-dark-blue-600',
    'md:hover:bg-grey-200',
);

const DEFAULT = classNames(
    BASICS,
    'bg-alt-color-600 text-white',
    'md:hover:bg-alt-color-900',
);

const DANGER = classNames(
    'border border-solid border-danger-500 text-danger-500',
    'hover:bg-danger-500 hover:text-white',
    'focus:bg-danger-500 focus:text-white',
);

const GHOST = classNames(
    'border border-solid border-gray-500 text-gray-800',
    'hover:bg-gray-500 hover:text-white',
    'focus:bg-gray-500 focus:text-white',
);

function Button({
    children,
    className,
    fullWidth,
    isLoading,
    margin,
    onClick,
}) {
    const conditionalClasses = {
        'w-full': fullWidth,
        'opacity-50': isLoading,
        'my-4': margin,
    };

    return (
        <button
            className={classNames(BASICS, className, conditionalClasses)}
            disabled={isLoading}
            onClick={onClick}
        >
            {isLoading && <Spinner className="absolute left-1rem" />}
            {children}
        </button>
    );
}

export function DangerButton(props) {
    return <Button {...props} className={classNames(DANGER, props.className)} />
}

export function GhostButton(props) {
    return <Button {...props} className={classNames(GHOST, props.className)} />
}

export function SubmitButton(props) {
    return <Button {...props} type="submit" className={classNames(DEFAULT, props.className)} />
}

export default function PrimaryButton(props) {
    return <Button {...props} className={classNames(PRIMARY, props.className)} />
}

Button.defaultProps
    = PrimaryButton.defaultProps
    = SubmitButton.defaultProps
    = GhostButton.defaultProps
    = DangerButton.defaultProps
    = {
        fullWidth: false,
        isLoading: false,
        margin: true,
    };
