import React, { createContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import { post, get } from 'axios'
import { useOAuthTokens } from 'src/hooks/useOAuthTokens'
import { dashboardRoute } from "src/routes";
import {
    convertToDateFormat,
    getTokenEndpoint,
    meEndpoint,
} from 'src/api'

const CLIENT_ID = process.env.API_CLIENT_ID;
const CLIENT_SECRET = process.env.API_CLIENT_SECRET;

const GuestApiContext = createContext({});
const { Provider } = GuestApiContext;

export function GuestApiProvider({ children }) {
    const { accessToken, setOAuthTokens } = useOAuthTokens();

    useEffect(function redirectToAuthenticationSection() {
        if (accessToken) {
            navigate(dashboardRoute);
        }
    }, [accessToken]);

    async function getToken(values) {
        const response = await post(getTokenEndpoint, {
            grant_type: 'password',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            ...values,
        });

        const {access_token} = response.data;

        if(access_token) {
            await checkAuthenticatedUserRole(access_token).then(authResponse => {
                const { data } = authResponse.data;
                if(data.admin_profile) {
                    setOAuthTokens({
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token,
                    });

                    return response
                }
            });
        }

        return {data: {status: 422}}
    }

    async function checkAuthenticatedUserRole(accessToken) {
        return await get(meEndpoint, {headers: { Authorization: `Bearer ${accessToken}` }});
    }



    return (
        <Provider value={{
            convertToDateFormat,
            getToken,
        }}>
            {children}
        </Provider>
    );
}

export default GuestApiContext;
