import React, { createContext, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { get, put } from 'axios'
import { useOAuthTokens } from 'src/hooks/useOAuthTokens'
import { loginRoute } from 'src/routes'
import { getHttpRequestStatusOk } from 'src/util';
import {
    //toDateFormat,
    fromDateFormat,
    convertToDateFormat,
    //toDateTimeFormat,
    fromDateTimeFormat,
    convertToDateTimeFormat,
    appointmentStatus,
    //transformRequest,
    transformResponse,
    meEndpoint,
    registeredUsersEndpoint,
    teacherEndpoint,
    approveTeacherEndpoint,
    hideTeacherEndpoint
} from 'src/api'

const AuthenticatedApiContext = createContext({});
const { Provider } = AuthenticatedApiContext;

export function AuthenticatedApiProvider({ children }) {
    const { accessToken, forgetOAuthTokens, refreshToken } = useOAuthTokens();
    const [headers, setHeaders] = useState(undefined);
    const [me, setMe] = useState({});
    const isLoading = !accessToken || !headers;

    useEffect(function redirectToLogin() {
        if (!accessToken) {
            navigate(loginRoute);
        }
    }, [accessToken]);

    useEffect(function setAuthorizationHeaders() {
        setHeaders({ Authorization: `Bearer ${accessToken}` });
    }, [accessToken]);

    useEffect(function fetchCustomerProfile() {
        if (headers) {
            getMe().then(me => setMe(me));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers]);

    async function handleUnauthorizedResponses(apiCall) {
        const response = await apiCall;
        if (!isUnauthorized(response)) {
            return response;
        }

        if (refreshToken){
            //TO-DO implement refresh token
            forgetOAuthTokens();
        } else {
            forgetOAuthTokens();
        }

        return await apiCall;
    }

    function isUnauthorized(response) {
        return response.status === 401;
    }

    function transformMeResponse(me) {
        return transformResponse({
            ...me,
            dateOfBirth: fromDateFormat(me.date_of_birth),
        });
    }

    async function getMe() {
        const apiCall = await get(meEndpoint, { headers });
        const response = await handleUnauthorizedResponses(apiCall);
        if (getHttpRequestStatusOk(response)) {
            return transformMeResponse(response.data.data);
        }
    }

    async function getTeachers(obj) {
        const response = await get(registeredUsersEndpoint, {params: { page: obj.page, profile_type: 'teacher', approved: obj.approved, search: obj.searchQuery }, headers: headers} );
        return response.data
    }

    async function getStudents(obj) {
        const response = await get(registeredUsersEndpoint, {params: { page: obj.page, profile_type: 'student', search: obj.searchQuery }, headers: headers} );
        return response.data
    }

    async function getTeacherById(id) {
        const response = await get(teacherEndpoint(id));
        return response.data.data
    }

    async function approveTeacher(id) {
        return await put(approveTeacherEndpoint(id), null, {headers});
    }

    async function hideTeacher(id) {
        return await put(hideTeacherEndpoint(id), null, {headers});
    }

    if (isLoading) {
        return null;
    }

    return (
        <Provider value={{
            convertToDateFormat,
            fromDateTimeFormat,
            convertToDateTimeFormat,
            appointmentStatus,
            me,
            getTeachers,
            getStudents,
            getTeacherById,
            approveTeacher,
            hideTeacher
        }}>
            {children}
        </Provider>
    );
}

export default AuthenticatedApiContext;
