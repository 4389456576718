module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"whambam-admin","short_name":"whambam","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/whambam-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c6e1323066b78bc5ad3608bed7730f96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Calibre","Icons"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"pathname":"/dashboard"},"crumbLabel":"Dashboard","crumbSeparator":" / "}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
