import React, { useMemo, useEffect, useState } from 'react'
import { Card, CardContent, CardHeader } from 'src/components/layout/card/Card'
import DataTable from 'react-data-table-component';
import { navigate } from 'gatsby'
import { useAuthenticatedApi } from "src/hooks/useApi";
import ProfilePic from '../graphics/profilepic/ProfilePic'
import Icon from '../graphics/icons/Icon'
import PrimaryButton from '../../components/interactivity/Button';
import SearchForm from '../interactivity/SearchForm'
import Puffer from '../graphics/loaders/Puffer'

export default function TeachersList({ className }) {
    const scrollHeight = false; //window.innerHeight / 2;
    const { getTeachers } = useAuthenticatedApi();
    const [teachers, setTeachers] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState(null);
    const [approvalFilter, setApprovalFilter] = useState(true);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(searchQuery !== null) {
            if(searchQuery.length >= 3) {
                fetchTeachers(1)
            } else if(searchQuery.length === 0) {
                fetchTeachers(1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        fetchTeachers(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalFilter]);

    function fetchTeachers(page) {
        getTeachers({page: page, searchQuery: searchQuery, approved: approvalFilter}).then((response) => {
            setTeachers(response.data)
            setPagination(response.meta)
            setPage(page)
            setLoading(false);
        })
    }

    const columns = useMemo(() => [
        {
            name: <h4 className="uppercase font-headers font-bold">Image</h4>,
            sortable: false,
            cell: row => <ProfilePic src={row.teacher_profile.profile_picture_url} size="50px" className="shadow-md" />,
        },
        {
            name: <h4 className="uppercase font-headers font-bold">ID</h4>,
            selector: 'id',
            sortable: false,
            /*style: {
                flex: '0 0 60px',
                minWidth: 'unset',
                borderRight: '1px solid #efefef',
            },*/
        },
        {
            name: <h4 className="uppercase font-headers font-bold">First name</h4>,
            selector: 'first_name',
            sortable: false,
        },
        {
            name: <h4 className="uppercase font-headers font-bold">Last name</h4>,
            selector: 'last_name',
            sortable: false,
        },
        {
            name: <h4 className="uppercase font-headers font-bold">Email</h4>,
            selector: 'email',
            sortable: false,
        },
        {
            name: <h4 className="uppercase font-headers font-bold">Approved</h4>,
            sortable: false,
            cell: row => <Icon width="20px" icon={row.teacher_profile.approved_at ? 'verified' : 'notverified'} />,
        },
        {
            name: '',
            cell: row => <PrimaryButton onClick={() => navigateToDetail(row)}>View</PrimaryButton>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ], []);

    function navigateToDetail (value) {
        navigate(`/teachers/${value.id}`, {state: {teacher: value}})
    }

    function changePage(value) {
        fetchTeachers(value)
    }

    return (
        <Card className={className}>
            <CardHeader title="Overview">
                <SearchForm
                    showApproved={true}
                    onApprovedChange={(value) => setApprovalFilter(value.target.value === '1')}
                    onQueryChange={(text) => setSearchQuery(text.target.value)}
                    />
            </CardHeader>
            <CardContent className="py-0">
                {loading ? <Puffer type="dark" className="py-32 flex justify-center items-center" /> : (pagination &&
                    <DataTable
                        columns={columns}
                        data={teachers}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={scrollHeight + 'px'}
                        pagination={true}
                        paginationServer={true}
                        paginationDefaultPage={1}
                        paginationTotalRows={pagination.total}
                        paginationPerPage={15}
                        paginationRowsPerPageOptions={[15]}
                        onChangePage={changePage}
                        progressPending={loading}
                        progressComponent={<Puffer type='dark' />}
                    />)
                }
            </CardContent>
        </Card>
    );
}
