// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-logout-jsx": () => import("./../../../src/pages/logout.jsx" /* webpackChunkName: "component---src-pages-logout-jsx" */),
  "component---src-pages-students-jsx": () => import("./../../../src/pages/students.jsx" /* webpackChunkName: "component---src-pages-students-jsx" */),
  "component---src-pages-teachers-jsx": () => import("./../../../src/pages/teachers.jsx" /* webpackChunkName: "component---src-pages-teachers-jsx" */)
}

