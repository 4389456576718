import React from 'react'
import { useGuestApi } from 'src/hooks/useApi'
import useForm from 'src/hooks/useForm'
import { Input, Checkbox, Error } from 'src/components/input'
import { SubmitButton } from 'src/components/interactivity/Button'
import { useTranslation } from 'react-i18next';

const initialValues = {
    username: '',
    password: '',
};

export default function LoginForm() {
    const { getToken } = useGuestApi();
    const { t } = useTranslation();
    const {
        values,
        onChangeValue,
        onChangeValueByName,
        errors,
        isLoading,
        submit,
    } = useForm({
        initialValues,
        onSubmit: getToken,
        onFail,
    });

    function onFail() {
        return { general: t('login.errors.invalidUsernameOrPassword') };
    }

    return (
        <form onSubmit={submit}>
            <div className="pt-6">
                <Input
                    isRequired
                    name="username"
                    value={values.username}
                    placeholder={t('login.email')}
                    onChange={onChangeValue}
                />
                <Input
                    isRequired
                    type="password"
                    name="password"
                    value={values.password}
                    placeholder={t('login.password')}
                    onChange={onChangeValue}
                />
                <div className="flex justify-between mt-6">
                    <Checkbox
                        name="rememberMe"
                        label={t('login.rememberMe')}
                        isChecked={values.rememberMe}
                        onChange={onChangeValueByName('rememberMe')}
                    />
                </div>
            </div>
            {errors.general && <Error>{errors.general}</Error>}
            <SubmitButton isLoading={isLoading}>{t('login.logIn')}</SubmitButton>
        </form>
    );
}
