import React  from 'react'
import classNames from 'classnames'

export default function Main({ page, className, children }) {
  return (
    <div className={classNames('py-10 px-4 md:px-8', className)}>
      {children}
    </div>
  );
}
