import React from 'react'
import classNames from 'classnames'

export default function ProfilePic({ src, alt, className, size }) {
  return (
    <div className={classNames('rounded-full overflow-hidden bg-dark-blue-150', className)} style={{width: size, height: size}}>
      <img src={src} alt={alt} className="w-full h-full" />
    </div>
  );
}

ProfilePic.defaultProps = {
  className: '',
  alt: '',
  size: '50px',
};
