import React from 'react';
import withAuthenticatedLayout from "src/hoc/withAuthenticatedLayout"
import { useTranslation } from 'react-i18next'
import PageTitle from "src/components/typography/PageTitle";
import { Card, CardHeader, CardContent } from 'src/components/layout/card/Card'
import DetailTable from '../layout/detail/DetailTable'
import Icon from '../graphics/icons/Icon'

function Detail({ id, location }) {
    const { t } = useTranslation();
    const { student } = location.state;

    function dateTime(dateString) {
        const dt = new Date(dateString);
        const day = dt.toLocaleDateString('en-BE', { time: true, weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        const time = `${dt.getHours()}u${dt.getMinutes()}`;
        return `${day} at ${time}`;
    }

    return (
        <>
            <PageTitle>{t('students.pageTitle')}</PageTitle>
            {/*<Breadcrumb location={location} crumbLabel={'Student Detail'} />*/}
            <Card className="mt-12">
                <CardHeader title={t('students.pageTitle') + ' details'} />
                <CardContent>

                    <DetailTable data={[
                        { name: 'Name', value: `${student.first_name} ${student.last_name}` },
                        { name: 'E-mail', value: <a href={`mailto:${student.email}`} className="md:hover:text-alt-color-900">{student.email}</a> },
                        { name: 'Phone', value: student.phone },
                        { name: 'Data of birth', value: student.date_of_birth },
                        { name: 'Country', value: student.country_code },
                        { name: 'Language', value: student.locale },
                        { name: 'E-mail verified', value: (
                            <div className="flex items-center justify-start">
                                <Icon width="20px" className="mr-4" icon={student.email_verified_at ? 'verified' : 'notverified' } />
                                <div className="leading-none">{student.email_verified_at ? dateTime(student.email_verified_at) : 'No'}</div>
                            </div>
                        )},
                    ]} />

                </CardContent>
            </Card>
        </>
    );
}

export default withAuthenticatedLayout(Detail, {
    page: 'students',
});
