import React from 'react'
import classNames from 'classnames'

const DEFAULT = 'font-medium text-danger-500 mb-4';

export default function Error({ className, children }) {
    if (!children) {
        return null;
    }

    return (
        <div className={classNames(DEFAULT, className)}>
            {children}
        </div>
    );
}

Error.defaultProps = {
    className: undefined,
};
