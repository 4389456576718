import { mapKeys, snakeCase, camelCase } from 'lodash'
import { DateTime } from 'luxon'

const BASE_URL = process.env.API_BASE_URL;
export const DATETIME_FORMAT = 'yyyy-LL-dd HH:mm';
export const DATE_FORMAT = 'yyyy-LL-dd';
export const appointmentStatus = {
    COMPLETED: 'completed',
}

export function toDateFormat(date) {
    if (! date instanceof DateTime) {
        return null;
    }

    return date.toFormat(DATE_FORMAT);
}

export function fromDateFormat(dateString) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, DATE_FORMAT);
}

export function convertToDateFormat(dateString, initialFormat) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, initialFormat).toFormat(DATE_FORMAT);
}

export function toDateTimeFormat(date) {
    if (! date instanceof DateTime) {
        return null;
    }

    return date.toFormat(DATETIME_FORMAT);
}

export function fromDateTimeFormat(dateString) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, DATETIME_FORMAT);
}

export function convertToDateTimeFormat(dateString, initialFormat) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, initialFormat).toFormat(DATETIME_FORMAT);
}

function endpoint(endpoint) {
    return `${BASE_URL}${endpoint}`;
}

export function transformRequest(request) {
    return mapKeys(request, (value, key) => snakeCase(key));
}

export function transformResponse(response) {
    return mapKeys(response, (value, key) => camelCase(key));
}

export const getTokenEndpoint = endpoint('/oauth/token');
export const meEndpoint = endpoint('/api/me');
export const registeredUsersEndpoint = endpoint('/api/registered-users');
export const teacherEndpoint = (id) => endpoint(`/api/teachers/${id}`);
export const approveTeacherEndpoint = (id) => endpoint(`/api/teachers/${id}/approve`);
export const hideTeacherEndpoint = (id) => endpoint(`/api/teachers/${id}/hide`);
