import React from 'react'
import { Error } from 'src/components/input/index'

const DEFAULT = 'flex font-normal text-gray-500 text-left mb-3';

export default function Checkbox({ name, label, error, isChecked, onChange }) {
    function handleChange(e) {
        onChange(e.target.checked);
    }

    return (
        <>
            <label htmlFor={name} className={DEFAULT}>
                <input
                    type="checkbox"
                    name={name}
                    className="mt-1 mr-2"
                    checked={isChecked}
                    onChange={handleChange}
                />
                {label}
            </label>
            <Error>{error}</Error>
        </>
    );
}

Checkbox.defaultProps = {
    error: undefined,
};
