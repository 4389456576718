import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardContent } from 'src/components/layout/card/Card'
import Icon from 'src/components/graphics/icons/Icon';
import {useAuthenticatedApi} from "src/hooks/useApi";
import {Link} from 'gatsby'
import Puffer from '../graphics/loaders/Puffer'

export default function TeacherCount() {
    const { getTeachers } = useAuthenticatedApi();
    const [teachersCount, setTeacherCount] = useState('...');
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        fetchTeachers(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchTeachers(page) {
        getTeachers(page).then((response) => {
            setTeacherCount(response.meta.total)
            setLoading(false)
        })
    }

    return (
        <Card className="flex-1 mb-6 md:mr-6">
            <CardHeader title="Number of teachers" />
            <CardContent>

                <Link to="/teachers" className={'flex justify-start items-center text-dark-blue-600'}>
                    <Icon icon="teacher" width="4rem" className="flex-none mr-10" />
                    {loading ? <Puffer type="dark" /> : <h1 className="block font-bold text-3xl leading-none">{teachersCount}</h1>}
                </Link>

            </CardContent>
        </Card>
    );
}
