import React from 'react'
import classNames from 'classnames'

export default function PageTitle({ className, children }) {
  const classes = classNames(
    'mb-4 font-headers text-white',
    'text-2xl md:text-3xl lg:text-4xl',
    'font-bold leading-tight',
    className,
  );

  return <h1 className={classes}>{children}</h1>;
}

PageTitle.defaultProps = {
  className: '',
};
