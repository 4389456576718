import React  from 'react'
//import { Link } from 'gatsby'
import classNames from 'classnames'

export default function Footer({ page, className }) {
  return (
    <footer className={classNames('mt-8 px-8 py-4 border-t border-grey-300', className)}>
      <hr className="hidden" />
      <div className="flex justify-between align-middle">
        <small>
          &copy;&nbsp;{new Date().getFullYear()} <a href="https://www.whambam.be" rel="noreferrer" target="_blank">Whambam</a>
        </small>
        {/*<small className="text-grey-500">
          <Link to="/">NL</Link>
          <span className="mx-2"> | </span>
          <Link to="/" className="text-dark-600 font-bold">EN</Link>
        </small>*/}
      </div>
    </footer>
  );
}
