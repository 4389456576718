import React from 'react'
import AuthenticatedLayout from "src/components/layout/wrappers/AuthenticatedLayout";
import { useTranslation } from 'react-i18next'
import TeachersList from './TeachersList'
import PageTitle from "src/components/typography/PageTitle";

function TeachersOverview({location}) {
    const { t } = useTranslation();
    return (
        <AuthenticatedLayout metaTitle={t('teachers.pageTitle')} page="teachers">
            <PageTitle>{t('teachers.pageTitle')}</PageTitle>
            <TeachersList className="mt-12"/>
        </AuthenticatedLayout>
    );
}
export default TeachersOverview
