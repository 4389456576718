import React from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next'
import i18n from 'src/i18n/i18next'

export default function ErrorLayout({ page, metaTitle, children }) {
  return (
    <I18nextProvider i18n={i18n}>
      <Helmet bodyAttributes={{class: 'page-' + page}}>
        <meta charSet="utf-8" />
        <title>{metaTitle && metaTitle.length ? metaTitle + ' | ' : '' }Whambam</title>
      </Helmet>
      <div className={classNames('wrapper h-screen flex justify-center items-center text-center')}>
        <div className="flex-auto h-16/9">
          {children}
        </div>
      </div>
    </I18nextProvider>
  );
}

ErrorLayout.defaultProps = {
  page: '',
  metaTitle: '',
};
