import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './translations/en'

export const SUPPORTED = ['en'];
export const FALLBACK = 'en';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: process.env.NODE_ENV === 'development',
        initImmediate: false,
        returnObjects: true,
        preload: SUPPORTED,
        whitelist: SUPPORTED,
        fallbackLng: FALLBACK,
        resources: {
            en: { translation: en }
        },
    });

export default i18n;
