import React from 'react'
import ErrorLayout from 'src/components/layout/wrappers/ErrorLayout'
import { useTranslation } from 'react-i18next'
import PageTitle from 'src/components/typography/PageTitle'

export default function Error404Page() {
  const { t } = useTranslation();

  return (
    <ErrorLayout metaTitle={t('errors.pageTitle')} page="error">
      <PageTitle>{t('errors.welcomeTitle')}</PageTitle>
      <p>{t('errors.404')}</p>
    </ErrorLayout>
  );
}
