import createPersistedState from 'use-persisted-state'

export function useOAuthTokens() {
    const [accessToken, setAccessToken] = createPersistedState('whambam-admin.oauth.accessToken')(null);
    const [refreshToken, setRefreshToken] = createPersistedState('whambam-admin.oauth.refreshToken')(null);

    function setOAuthTokens({ accessToken, refreshToken }) {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
    }

    function forgetOAuthTokens() {
        setAccessToken(null);
        setRefreshToken(null);
    }

    return {
        accessToken,
        refreshToken,
        setOAuthTokens,
        forgetOAuthTokens,
    };
}
