import React, {useState} from 'react';
import withAuthenticatedLayout from "src/hoc/withAuthenticatedLayout"
import { useTranslation } from 'react-i18next'
import PageTitle from "src/components/typography/PageTitle";
import { Card, CardHeader, CardContent } from 'src/components/layout/card/Card'
import { SubmitButton as Button } from 'src/components/interactivity/Button'
import {useAuthenticatedApi} from "src/hooks/useApi";
import DetailTable from '../layout/detail/DetailTable'
import ProfilePic from '../graphics/profilepic/ProfilePic'
import Icon from '../graphics/icons/Icon'
import Puffer from '../graphics/loaders/Puffer'

function Detail({ location }) {
    const { t } = useTranslation();
    const [teacher, setTeacher] = useState(location.state.teacher)
    const { approveTeacher, hideTeacher } = useAuthenticatedApi();
    const [loading, setLoading] = useState(false)

    function submitApprove(id) {
        setLoading(true)
        approveTeacher(id).then((response) => {
            teacher.teacher_profile = response.data.data;
            setTeacher(teacher)
            setLoading(false)
        })
    }

    function submitHide(id) {
        setLoading(true)
        hideTeacher(id).then((response) => {
            teacher.teacher_profile = response.data.data;
            setTeacher(teacher)
            setLoading(false)
        })
    }

    function dateTime(dateString) {
        const dt = new Date(dateString);
        const day = dt.toLocaleDateString('en-BE', { time: true, weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        const time = `${dt.getHours()}u${dt.getMinutes()}`;
        return `${day} at ${time}`;
    }

    return (
        <>
            <PageTitle>{t('teachers.pageTitle')}</PageTitle>
            {loading && <Puffer />}
            {teacher && <div className="mt-12 md:flex">
                <Card className="md:flex-1 md:mr-2 mb-4">
                    <CardHeader title={t('teachers.pageTitle') + ' details'}/>
                    <CardContent>

                        <ProfilePic src={teacher.teacher_profile.profile_picture_url} alt={`${teacher.first_name} ${teacher.last_name}`} size="100px" className="mb-8 md:ml-40 md:my-12 shadow-xl" />
                        <DetailTable data={[
                            { name: 'Name', value: `${teacher.first_name} ${teacher.last_name}` },
                            { name: 'E-mail', value: <a href={`mailto:${teacher.email}`} className="md:hover:text-alt-color-900">{teacher.email}</a> },
                            { name: 'Phone', value: teacher.phone },
                            { name: 'Data of birth', value: teacher.date_of_birth },
                            { name: 'Country', value: teacher.country_code },
                            { name: 'Language', value: teacher.locale },
                            { name: 'E-mail verified', value: (
                                <div className="flex items-center justify-start">
                                    <Icon width="20px" className="mr-4" icon={teacher.email_verified_at ? 'verified' : 'notverified' } />
                                    <div className="leading-none">{teacher.email_verified_at ? dateTime(teacher.email_verified_at) : 'No'}</div>
                                </div>
                            )},
                        ]} />

                    </CardContent>
                </Card>
                {!teacher.teacher_profile.approved_at ?
                    <Card className="md:flex-1 md:ml-2 mb-4">
                        <CardHeader title="Approve teacher?"/>
                        <CardContent>
                            <p>Allow the teacher to be approved:</p>
                            <Button onClick={() => submitApprove(teacher.teacher_profile.id)}>Approve</Button>
                        </CardContent>
                    </Card>
                    :
                    <Card className="md:flex-1 md:ml-2 mb-4">
                        <CardHeader title="Hide teacher?"/>
                        <CardContent>
                            <p>Hide the teacher:</p>
                            <Button onClick={() => submitHide(teacher.teacher_profile.id)}>Hide</Button>
                        </CardContent>
                    </Card>
                }
            </div>
            }
        </>
    );
}

export default withAuthenticatedLayout(Detail, {
    page: 'teachers',
});
